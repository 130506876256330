import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { withRouter } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import { AccessId } from "constants/ConstantFields"
import { DealManagementAccess } from "common/AccessManagement"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import Loader from "common/Loader"
import ReactSelect from "constants/ReactSelect"
import { csvDownloadData, investmentData } from "constants/common"
import { JSONToCSVConvertor } from "common/jsontocsv"
import { AvField, AvForm } from "availity-reactstrap-validation"
import getBase64 from "base64"

const DematTransfers = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [total, setTotal] = useState(0)
  const [investmentFilter, setInvestmentFilter] = useState({})
  const [investmentList, setInvestmentList] = useState([])
  const [userFilter, setUserFilter] = useState("")
  const [modal, setModal] = useState(false)
  const [base64, setBase64] = useState("")
  const [file, setFile] = useState()
  const [executionDateFilter, setExecutionDateFilter] = useState("")
  const [addModal, setAddModal] = useState(false)
  const [searchValue, setSearchValue] = useState("")
  const [users, setUsers] = useState([])
  const [selectedUser, setSelectedUser] = useState({})
  const [selectedProject, setSelectedProject] = useState({})
  const [stfDocuments, setStfDocuments] = useState([])
  const [selectedStfDocument, setSelectedStfDocument] = useState({})

  const filterUrl = `${userFilter.length >= 3 ? `&name=${userFilter}` : ``}${
    investmentFilter?.id ? `&investmentId=${investmentFilter.id}` : ""
  }${executionDateFilter ? `&execution_date=${executionDateFilter}` : ""}`

  const masterData = async () => {
    setLoading(true)

    const getUrl = `demat-transfers?$limit=${limit}&$skip=${skip}&$sort[created_at]=-1${filterUrl}`
    try {
      const response = await axiosInstance.get(getUrl)

      if (response) {
        setTotal(response.data.total)
        setData(response.data?.data || response.data)
        let pages = Math.ceil(
          (response.data?.total || response.total) /
            (response.data?.limit || response.limit)
        )
        setTotalPage(pages)
      }
    } catch (error) {
      toast.error(error?.message)
    } finally {
      setLoading(false)
    }
  }

  const getFiltersDataList = async () => {
    try {
      setLoading(true)

      const invResponse = await investmentData("Approved", "Exited", "Closed")
      if (invResponse) {
        setInvestmentList(invResponse?.data?.data || invResponse?.data)
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getFiltersDataList()
  }, [])

  useEffect(() => {
    masterData()
  }, [limit, skip, investmentFilter, executionDateFilter])

  useEffect(() => {
    if (!userFilter || userFilter?.length >= 3) {
      masterData()
    }
  }, [userFilter])

  const accessRestriction = DealManagementAccess(AccessId?.TRANSACTION)

  const Columns = () => {
    let col = [
      {
        dataField: "execution_date",
        text: "Execution Date",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => handleValidDate(cellContent),
      },
      {
        dataField: "investment.project_name",
        text: "Project Name",
        sort: true,
      },
      {
        dataField: "user.user_name",
        text: "User Name",
        sort: true,
      },
      {
        dataField: "user.email",
        text: "User Email",
        sort: true,
      },

      {
        dataField: "user.phone",
        text: "User Phone",
        sort: true,
      },

      {
        dataField: "bp_instruction_id",
        text: "BP Instruction ID",
        sort: true,
      },
      {
        dataField: "target_dp_id",
        text: "Target DP ID",
        sort: true,
      },
      {
        dataField: "target_client_id",
        text: "Target Client ID",
        sort: true,
      },
      {
        dataField: "isin",
        text: "ISIN",
        sort: true,
      },
      {
        dataField: "security_name",
        text: "Security Name",
        sort: true,
      },
      {
        dataField: "quantity",
        text: "Quantity",
        sort: true,
        // eslint-disable-next-line react/display-name
        headerFormatter: (column, colIndex) => (
          <div className="text-end">{column.text}</div>
        ),
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div className="text-end">{cellContent}</div>
        ),
      },
      {
        dataField: "consideration_amount",
        text: "Consideration Amount",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div className="text-end">{handleAmount(cellContent)}</div>
        ),
        // eslint-disable-next-line react/display-name
        headerFormatter: (column, colIndex) => (
          <div className="text-end">{column.text}</div>
        ),
      },
      {
        dataField: "stamp_duty",
        text: "Stamp Duty Payable",
        sort: true,
        // eslint-disable-next-line react/display-name
        headerFormatter: (column, colIndex) => (
          <div className="text-end">{column.text}</div>
        ),
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div className="text-end">{cellContent}</div>
        ),
      },
      {
        dataField: "stamp_duty_paid_by",
        text: "Stamp duty paid by",
        sort: true,
      },
      {
        dataField: "stfDocumentId",
        text: "STF Document Id",
        sort: true,
      },
    ]

    return col
  }

  const dollarIndianLocale = Intl.NumberFormat("en-IN")
  const handleAmount = amount => {
    return dollarIndianLocale.format(amount)
  }

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM Y")
    return date1
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  const handleNext = prev => {
    setSkip(prev + limit)
    let page = (prev + limit) / limit + 1
    setCurrentPage(page)
  }

  const handlePrevious = prev => {
    setSkip(prev - limit)
    let page = (prev - limit) / limit + 1
    setCurrentPage(page)
  }

  const onChangePagination = e => {
    const { value } = e.target
    if (value < 1 || value > totalPage) {
      return
    }
    setCurrentPage(value)
    if (value) {
      setSkip((value - 1) * limit)
    }
  }

  const handleFilterChange = async (e, key) => {
    switch (key) {
      case "user":
        setUserFilter(e.target.value)
        break
      case "projectType":
        setInvestmentFilter(e)
        break
      case "limit":
        setLimit(Number(e.target.value))
        break
      case "execution_date":
        setExecutionDateFilter(e.target.value)
        break

      default:
        break
    }
    setSkip(0)
    setCurrentPage(1)
  }

  const downloadData = async () => {
    try {
      setLoading(true)

      const fileName = `demat transfers`
      const url = `/demat-transfers?$sort[created_at]=-1${filterUrl}`

      const res = await axiosInstance.get(url)
      if (res) {
        const data = res.data?.data
        const csvTableHeaders = Columns()
        const str = JSON.stringify(data)
        const arr = JSON.parse(str)
        arr.forEach(
          el => (el.createdByName = el?.createdBy?.distributor_kyc?.name || "")
        )
        const downladableArr = csvDownloadData(csvTableHeaders, arr)
        JSONToCSVConvertor(downladableArr, fileName, true)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      toast.error(error?.message)
    }
  }

  const toggle = () => {
    setModal(!modal)
  }
  const toggleAddModal = () => {
    setAddModal(!addModal)
  }
  const handleOrderClicks = async () => {
    setFile("")
    setBase64("")
    toggle()
  }

  const handleAddButtonClick = () => {
    setUsers([])
    setSelectedUser()
    setSelectedProject()
    setSelectedStfDocument({})
    setStfDocuments([])
    setSearchValue("")
    toggleAddModal()
  }

  const handleValidOrderSubmit = async (e, values) => {
    if (!file) {
      toast.error("Select a file")
      return
    }
    const dataToUpload = {
      base64_string: base64,
      execution_date: values.execution_date,
    }
    if (base64) {
      try {
        setLoading(true)
        const uploadResponse = await axiosInstance.post(
          "/upload-base-64?type=demat_transfers",
          dataToUpload
        )
        if (uploadResponse) {
          toast.success("Sucessfully Uploaded")
          masterData()
          toggle()
          setBase64("")
        }
      } catch (error) {
        const msg = error?.response?.data.message
        // toast.error(msg)
      } finally {
        setLoading(false)
      }
    } else {
      toast.error("Please select valid file")
      return false
    }
  }

  const handleValidFormSubmit = async (e, values) => {
    try {
      setLoading(true)
      if (!selectedProject?.id) {
        toast.error("Select a project")
        return
      }
      if (!selectedUser?.id) {
        toast.error("Select a user")
        return
      }
      const payload = {
        execution_date: values.execution_date,
        bp_instruction_id: values.bp_instruction_id,
        target_dp_id: selectedUser?.user_demat?.demat_id?.slice(0, 8),
        target_client_id: selectedUser?.user_demat?.demat_id?.slice(8),
        isin: selectedProject.isin_number,
        security_name: selectedProject.isin_description,
        quantity: selectedStfDocument?.user_investment_topup?.lot_size,
        consideration_amount:
          selectedStfDocument?.user_investment_topup?.amount,
        stamp_duty: values.stamp_duty,
        stamp_duty_paid_by: values.stamp_duty_paid_by,
        investmentId: selectedProject.id,
        userId: selectedUser.id,
        stfDocumentId: selectedStfDocument.id,
      }

      const res = await axiosInstance.post(`/demat-transfers`, payload)
      await masterData()
      toggleAddModal()
      toast.success("Submitted successfully")
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }
  useEffect(async () => {
    if (file) {
      setLoading(true)
      new Promise((resolve, reject) => {
        getBase64(file, data => resolve(data))
      }).then(result => {
        setBase64(result)
        setLoading(false)
      })
    }
  }, [file])

  const handleUserSearch = async e => {
    setSearchValue(e)
    if (e.length >= 3) {
      setLoading(true)
      if (e) {
        const usersRes = await axiosInstance.get(
          `/users?$sort[created_at]=-1&$or[0][permissions]=USER&$or[1][permissions]=OPS_USER
            &name=${e}&$limit=50&$skip=0&is_registered=true&kycStatus=VERIFIED`
        )
        const users = usersRes?.data?.data || usersRes?.data
        if (users) {
          setUsers(users)
        } else {
          toast.error("something went wrong")
        }
      } else {
        setUsers([])
      }
      setLoading(false)
    }
  }

  const getStfDocuments = async () => {
    try {
      setLoading(true)
      setStfDocuments([])
      setSelectedStfDocument()
      const res = await axiosInstance.get(
        `/stf-document?$sort[created_at]=-1&investmentId=${selectedProject.id}&userId=${selectedUser.id}`
      )
      let data = res.data.data.filter(obj => !obj.demat_transfer)
      if (data.length) {
        setStfDocuments(data)
      } else {
        toast.error("No stf document found which is not credited")
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (selectedUser?.id && selectedProject?.id) {
      getStfDocuments()
    } else {
      setStfDocuments([])
      setSelectedStfDocument()
    }
  }, [selectedUser, selectedProject])

  const handleStfSelection = e => {
    const selectedStf = stfDocuments.find(val => val.id == e.target.value)
    setSelectedStfDocument(selectedStf)
  }

  const calculateStampDuty = amount => {
    return Number(amount * (0.001 / 100)).toFixed(2)
  }
  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Demat Transfers" />
          <Row>
            <Col xs="12">
              <Row className="mb-2 row justify-content-between">
                <Col md={2}>
                  <select
                    className="form-select w-75"
                    value={limit}
                    onChange={e => handleFilterChange(e, "limit")}
                  >
                    {[10, 30, 50, 100].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                  </select>
                </Col>
                <div className="col-auto">
                  {(accessRestriction >= 1 ||
                    accessRestriction === "SuperAdmin") && (
                    <Button
                      type="button"
                      color="success"
                      className="btn-rounded  mb-2 me-2"
                      onClick={handleAddButtonClick}
                    >
                      <i className="mdi mdi-plus me-1" /> Add
                    </Button>
                  )}
                  {(accessRestriction >= 1 ||
                    accessRestriction === "SuperAdmin") && (
                    <Button
                      type="button"
                      color="primary"
                      className="btn-rounded  mb-2 me-2"
                      onClick={handleOrderClicks}
                    >
                      <i className="mdi mdi-arrow-up-bold-circle" /> Upload
                      Excel
                    </Button>
                  )}
                  {(accessRestriction == "4" ||
                    accessRestriction === "SuperAdmin") && (
                    <Button
                      type="button"
                      color="primary"
                      className="btn-rounded  mb-2 me-2"
                      onClick={downloadData}
                      style={{ marginRight: "3px" }}
                    >
                      <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                      Download
                    </Button>
                  )}
                </div>
              </Row>
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={data}
                    columns={Columns()}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2 row">
                          <Col md={3}>
                            <div className="col-auto">
                              <label>Search User</label>
                              <input
                                onChange={e => handleFilterChange(e, "user")}
                                id="search-bar-0"
                                type="text"
                                className="form-control rounded custom-input-height"
                                placeholder={`Search Name, Email, Phone`}
                                value={userFilter || ""}
                              />
                              {/* <i className="bx bx-search-alt"></i> */}
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <label>Project Name</label>
                              <ReactSelect
                                users={investmentList}
                                setSelectedOption={e =>
                                  handleFilterChange(e, "projectType")
                                }
                                multiOptionLabel={true}
                                optionLabelKeys={["project_name"]}
                                isClearable={true}
                              />
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <label>Execution Date</label>

                              <input
                                onChange={e =>
                                  handleFilterChange(e, "execution_date")
                                }
                                type="date"
                                className="form-control rounded custom-input-height"
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="12">
                            <BootstrapTable
                              keyField="id"
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              // selectRow={selectRow}
                              columns={Columns()}
                              data={data}
                              wrapperClasses={"table-responsive mb-4"}
                              classes={
                                "table align-middle table-nowrap table-check"
                              }
                              headerWrapperClasses={"table-light"}
                              {...toolkitProps.baseProps}
                            />
                          </Col>
                        </Row>
                        <Row className="justify-content-md-space-between justify-content-center align-items-center">
                          <Col className="col-12 col-md-auto mb-3">
                            {`Showing ${total ? skip + 1 + " to" : ""} ${
                              limit > total || limit + skip > total
                                ? total
                                : limit + skip
                            } rows of ${total}
                    `}
                          </Col>
                          <Col>
                            <Row className="justify-content-md-end justify-content-center align-items-center">
                              <Col className="col-md-auto">
                                <div className="d-flex gap-1">
                                  <Button
                                    color="primary"
                                    onClick={() => handlePrevious(limit)}
                                    disabled={currentPage == 1}
                                  >
                                    {"<<"}
                                  </Button>
                                  <Button
                                    color="primary"
                                    onClick={() => handlePrevious(skip)}
                                    disabled={currentPage == 1}
                                  >
                                    {"<"}
                                  </Button>
                                </div>
                              </Col>
                              <Col className="col-md-auto d-none d-md-block">
                                Page{" "}
                                <strong>{`${currentPage ? currentPage : 1} of ${
                                  totalPage ? totalPage : 1
                                }`}</strong>
                              </Col>
                              <Col className="col-md-auto">
                                <Input
                                  type="number"
                                  min={1}
                                  style={{ width: 70 }}
                                  max={total == 0 ? 1 : totalPage}
                                  value={currentPage || 1}
                                  defaultValue={1}
                                  onChange={onChangePagination}
                                  disabled={total == 0}
                                />
                              </Col>

                              <Col className="col-md-auto">
                                <div className="d-flex gap-1">
                                  <Button
                                    color="primary"
                                    onClick={() => handleNext(skip)}
                                    disabled={
                                      currentPage == totalPage || total == 0
                                    }
                                  >
                                    {">"}
                                  </Button>
                                  <Button
                                    color="primary"
                                    onClick={() =>
                                      handleNext((totalPage - 2) * limit)
                                    }
                                    disabled={
                                      currentPage == totalPage || total == 0
                                    }
                                  >
                                    {">>"}
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              Upload Demat Transfers
            </ModalHeader>
            <ModalBody>
              <AvForm onValidSubmit={handleValidOrderSubmit}>
                <Row form>
                  <Col className="col-12 mb-3">
                    <AvField
                      name="execution_date"
                      label="Execution Date"
                      type="date"
                      errorMessage="Invalid Value"
                      validate={{
                        required: { value: true },
                      }}
                    ></AvField>
                  </Col>

                  <Col className="col-12 mb-3">
                    <Label htmlFor="formFile" className="form-label">
                      Upload Excel <b>*</b>
                    </Label>
                    <Input
                      className="form-control"
                      type="file"
                      accept=".xls,.xlsx"
                      id="formFile"
                      validate={{
                        required: { value: true },
                      }}
                      onChange={e => {
                        setFile(e.target.files[0])
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </ModalBody>
          </Modal>
          <Modal isOpen={addModal} toggle={toggleAddModal} className="modal-lg">
            <ModalHeader toggle={toggleAddModal} tag="h4">
              Add Demat Transfers
            </ModalHeader>
            <ModalBody>
              <AvForm onValidSubmit={handleValidFormSubmit}>
                <div className="row" form>
                  <div className="mb-3 col-md-6">
                    <label>Select Project Name by ISIN</label>
                    <ReactSelect
                      users={investmentList}
                      setSelectedOption={setSelectedProject}
                      selectedOption={selectedProject}
                      multiOptionLabel={true}
                      optionLabelKeys={["project_name", "isin_number"]}
                      // isClearable={true}
                    />
                  </div>
                  <div className="mb-3 col-md-6">
                    <label>User (Search by demat id)</label>
                    <ReactSelect
                      users={users}
                      searchValue={searchValue}
                      setSearchValue={handleUserSearch}
                      selectedOption={selectedUser}
                      setSelectedOption={setSelectedUser}
                      multiOptionLabel={false}
                      optionLabelKeys={["user_name", "user_demat?.demat_id"]}
                    />
                  </div>
                  <div className="mb-3 col-md-6">
                    <AvField
                      name="topup"
                      label="Select STF Document"
                      type="select"
                      className="form-select"
                      errorMessage="Invalid value"
                      sort="true"
                      validate={{
                        required: { value: true },
                      }}
                      value={selectedStfDocument?.id || ""}
                      onChange={handleStfSelection}
                      disabled={!selectedUser?.id || !selectedProject?.id}
                    >
                      <option disabled value="">
                        Select
                      </option>
                      {stfDocuments?.map((item, index) => (
                        <option key={item?.id} value={item?.id}>
                          Quantity: {item?.user_investment_topup?.lot_size},
                          Amount:{" "}
                          {handleAmount(item?.user_investment_topup?.amount)},
                          Investment Date:{" "}
                          {moment(
                            item?.user_investment_topup?.created_at
                          ).format("DD-MM-YYYY")}
                        </option>
                      ))}
                    </AvField>
                  </div>
                  <div className="mb-3 col-md-6">
                    <AvField
                      name="quantity"
                      label="Quantity"
                      type="text"
                      errorMessage="Invalid value"
                      validate={{
                        required: { value: true },
                      }}
                      disabled={true}
                      value={
                        selectedStfDocument?.user_investment_topup?.lot_size
                          ? selectedStfDocument?.user_investment_topup?.lot_size
                          : ""
                      }
                    ></AvField>
                  </div>
                  <div className="mb-3 col-md-6">
                    <AvField
                      name="amount"
                      label="Consideration Amount"
                      type="text"
                      errorMessage="Invalid value"
                      validate={{
                        required: { value: true },
                      }}
                      disabled={true}
                      value={
                        selectedStfDocument?.user_investment_topup?.amount
                          ? handleAmount(
                              selectedStfDocument?.user_investment_topup?.amount
                            )
                          : ""
                      }
                    ></AvField>
                  </div>
                  <div className="mb-3 col-md-6">
                    <AvField
                      name="stamp_duty"
                      label="Stamp Duty Payable"
                      type="text"
                      errorMessage="Invalid value"
                      validate={{
                        required: { value: true },
                      }}
                      value={
                        selectedStfDocument?.user_investment_topup?.amount
                          ? handleAmount(
                              calculateStampDuty(
                                selectedStfDocument?.user_investment_topup
                                  ?.amount
                              )
                            )
                          : ""
                      }
                    ></AvField>
                  </div>
                  <div className="mb-3 col-md-6">
                    <AvField
                      name="stamp_duty_paid_by"
                      label="Stamp Duty Paid By"
                      type="text"
                      errorMessage="Invalid value"
                      validate={{
                        required: { value: true },
                      }}
                      value={"DP"}
                    ></AvField>
                  </div>
                  <div className="mb-3 col-md-6">
                    <AvField
                      name="execution_date"
                      label="Execution Date"
                      type="date"
                      errorMessage="Invalid Value"
                      validate={{
                        required: { value: true },
                      }}
                    ></AvField>
                  </div>
                  <div className="mb-3 col-md-6">
                    <AvField
                      name="bp_instruction_id"
                      label="BP Instruction Id"
                      type="text"
                      errorMessage="Invalid value"
                      validate={{
                        required: { value: true },
                      }}
                    ></AvField>
                  </div>
                </div>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Submit
                      </button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(DematTransfers)
